import React from "react";
import { Button, Container, Row, Col } from "reactstrap";

function MyCol(props: any) {
    return (
        <Col xs={{ size: 6, offset: 3 }} className={props.className}>
            {props.children}
        </Col>
    );
}

function Link(props: any) {
    return (
        <Row className="mb-2">
            <MyCol>
                <Button href={props.url} className="w-100" size="lg" color="secondary">
                    {props.children}
                </Button>
            </MyCol>
        </Row>
    );
}

function App() {
    return (
        <Container fluid>
            <Row className="mb-4 mt-4">
                <MyCol className="text-center">
                    <img alt="Grahame and his cat, Ahelie" src="gb.jpg" className="rounded-circle" />
                </MyCol>
            </Row>
            <Row className="mb-2 mt-4">
                <MyCol>
                    <h3 className="text-center">Grahame Bowland's software</h3>
                    <p className="mt-4">
                        For more about me, see my <a href="https://grahame.theol.au">blog</a>, which also links to my
                        various social media accounts. Source for my software is up on{" "}
                        <a href="https://github.com/grahame">GitHub</a>.
                    </p>
                </MyCol>
            </Row>
            <Link url="https://exegete.app/">exegete.app</Link>
            <Link url="/baraq/">Biblical Hebrew Flash Cards</Link>
            <Link url="/shmuelof/">Abraham Shmuelof's recordings of the Tanakh</Link>
            <Link url="/tweed/">My library</Link>
        </Container>
    );
}

export default App;
